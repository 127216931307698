/* eslint-disable no-unused-vars */
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from '../../common/components/LocalizationProvider';

const MaintenanceCategoryDetails = ({ category }) => {
  const t = useTranslation();
  const [item, setItem] = useState(category);

  const handleAddDetail = () => {
    const currentItem = { ...item };
    currentItem.categoryMaintenance.details.push('');
    currentItem.edited = true;
    setItem(currentItem);
  };

  const handleDeleteDetail = (index) => {
    const currentItem = { ...item };
    const newDetails = currentItem.categoryMaintenance.details.filter((_, i) => i !== index);
    currentItem.categoryMaintenance.details = newDetails;
    currentItem.edited = true;
    setItem(currentItem);
  };

  const handleSave = async () => {
    let currentItem = { ...item };
    currentItem.loading = true;
    setItem(currentItem);
    const result = await fetch('/api/express/permissions', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(item.categoryMaintenance),
    });
    if (result.ok) {
      currentItem = { ...item };
      currentItem.edited = false;
      setItem(currentItem);
    }
    currentItem.loading = false;
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
          <Typography variant="subtitle2">{t(`category${item.name.replace(/^\w/, (c) => c.toUpperCase())}`)}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" spacing={1}>

          {
            item.categoryMaintenance?.details?.map((detail, index) => (
              <TextField
                // eslint-disable-next-line react/no-array-index-key
                key={`detail-${index}`}
                value={detail}
                onChange={(e) => {
                  const currentItem = { ...item };
                  currentItem.categoryMaintenance.details[index] = e.target.value;
                  setItem(currentItem);
                }}
                fullWidth
                multiline
                maxRows={4}
                InputProps={{
                  endAdornment: (
                    <IconButton size="small" onClick={() => handleDeleteDetail(index)}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            ))
          }
        </Stack>
      </AccordionDetails>
      <AccordionActions>
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ p: 2, minWidth: 0, height: 24, width: 24 }}
            onClick={handleAddDetail}
          >
            <AddIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ p: 2, minWidth: 24, height: 24, width: 24 }}
            onClick={!item.loading ? handleSave : () => { }}
            disabled={!item.edited}
          >
            {item.loading ? <Box sx={{ height: 24, width: 24 }}><CircularProgress size={24} color="inherit" /></Box> : <SaveIcon />}
          </Button>
        </Stack>
      </AccordionActions>
    </Accordion>
  );
};

export default MaintenanceCategoryDetails;

/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import {
  TableRow, TableCell, Grid,
  Box,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  axisClasses, ChartsAxisHighlight, ChartsXAxis, ChartsYAxis,
} from '@mui/x-charts';
import {
  formatDistance, formatHours, formatHoursToMsec, formatMsecToHours, formatNumericHours, formatRoundedNumericHours, formatSpeed, formatTime, formatVolume,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import { useCatch } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import { prefixString } from '../common/util/stringUtils';
import VirtualizedTable from './components/VirtualizedTable';
import VirtualizedTableShimmer from '../common/components/VirtualizedTableShimmer';
import CorporateReportFilter from './components/CorporateReportFilter';
import palette from '../common/theme/palette';
import CustomChart from './components/CustomChart';
import CustomDataGrid from './components/CustomDataGrid';
import { getProduction, AchievementStatusIcon } from '../common/util/corporateReportUtils';
import TotalCard from './components/TotalCard';

const engineHourColumnsGenerator = (t, p) => ([
  {
    field: 'productionStatus',
    headerName: t('deviceStatus'),
    type: 'string',
    valueGetter: (_value, item) => getProduction(item) / item.goal,
    renderCell: (item) => <AchievementStatusIcon {...item.row} />,
    width: 100,
  },
  {
    field: 'device',
    headerName: t('sharedDevice'),
    type: 'string',
    valueGetter: (_value, row) => row.name.toUpperCase(),
    width: 200,
  },
  {
    field: 'engineHours',
    headerName: t('reportEngineHours'),
    type: 'number',
    valueGetter: (_value, row) => row.totalHours,
    valueFormatter: (v) => formatRoundedNumericHours(v, t),
    width: 120,
  },
  {
    field: 'spentFuel',
    headerName: t('reportSpentFuel'),
    type: 'number',
    valueGetter: (_value, row) => row.spentFuel,
    valueFormatter: (v) => formatVolume(v, p.volumeUnit, t, 0),
    width: 100,
  },

]);
const pumpedMaterialColumnsGenerator = (t, p) => ([
  {
    field: 'productionStatus',
    headerName: t('deviceStatus'),
    type: 'string',
    valueGetter: (_value, item) => getProduction(item) / item.goal,
    renderCell: (item) => <AchievementStatusIcon {...item.row} />,
    width: 100,
  },
  {
    field: 'device',
    headerName: t('sharedDevice'),
    type: 'string',
    valueGetter: (_value, row) => row.name.toUpperCase(),
    width: 200,
  },
  {
    field: 'pumpedMaterial',
    headerName: t('reportPumpedMaterial'),
    type: 'number',
    valueGetter: (_value, row) => row.productionValue,
    valueFormatter: (v) => `${Math.round(v) || 0} ${t('sharedCubicMeterAbbreviation')}`,
    width: 120,
  },
  {
    field: 'engineHours',
    headerName: t('reportEngineHours'),
    type: 'number',
    valueGetter: (_value, row) => row.totalHours,
    valueFormatter: (v) => formatRoundedNumericHours(v, t),
    width: 120,
  },
  {
    field: 'spentFuel',
    headerName: t('reportSpentFuel'),
    type: 'number',
    valueGetter: (_value, row) => row.spentFuel,
    valueFormatter: (v) => formatVolume(v, p.volumeUnit, t, 0),
    width: 100,
  },

]);

const top5Columns = ['branch', 'construction', 'project'];
const top5ColumnsArray = [
  ['branch', 'reportBranch'],
  ['construction', 'reportConstruction'],
  ['project', 'reportProject'],
];

const columnsMap = new Map([...top5ColumnsArray]);

const CorporateGoalsPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const groups = useSelector((state) => state.groups.items);
  const categories = useSelector((state) => state.categories.items);

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const engineHoursColumns = useMemo(() => engineHourColumnsGenerator(t, { volumeUnit }), [t, volumeUnit]);
  const pumpedMaterialColumns = useMemo(() => pumpedMaterialColumnsGenerator(t, { volumeUnit }), [t, volumeUnit]);

  const [items, setItems] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState({ from: '', to: '' });

  const [selectedItems, setSelectedItems] = useState([]);
  const showItems = useMemo(() => items.filter((item) => (category !== '' ? item.category === category : true)), [items, category]);
  const chartItems = useMemo(() => showItems.filter((item) => selectedItems.includes(item.deviceid)), [showItems, selectedItems]);

  const filterItems = (list, key, value) => [...list].filter((item) => item[key] === value);
  const sortedItems = (list, key) => [...list].sort((a, b) => (parseInt(b[key], 10) || 0) - (parseInt(a[key], 10) || 0));
  const truncateItems = (list, count) => [...list].slice(0, count);

  const dailyTotal = (items, key) => {
    items = items.filter((item) => item.productionUnit === key);
    const dates = [];
    const currentDate = new Date(dateRange.from);
    const endDate = new Date(dateRange.to);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate).toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const dataset = [];

    dates.forEach((day, index) => {
      let activeDevices = 0;
      const total = items.reduce((acc, item) => {
        const datePosition = item.dates.findIndex((date) => date === day);
        if (datePosition > -1) {
          activeDevices += 1;
          const newProduction = parseInt(item.productionValues[datePosition], 10) || 0;
          if (acc.cummulative !== null) {
            acc.cummulative += newProduction;
          } else {
            acc.cummulative = newProduction;
          }
          const itemCategory = categories[item.category];
          const productionGoal = parseInt(itemCategory.productionGoal, 10) || 0;
          const productionTimePeriod = parseInt(itemCategory.productionTimePeriod, 10) || 0;
          const expected = (productionGoal * parseInt(item.days[datePosition], 10)) / productionTimePeriod;
          switch (itemCategory.productionUnit) {
            case 'pumpedMaterial':
              acc.expected += expected;
              break;
            case 'engineHours':
              acc.expected += formatHoursToMsec(expected);
              break;
            default:
              break;
          }
          return acc;
        }
        return acc;
      }, { cummulative: null, expected: null });
      if (total.cummulative === null) {
        total.expected = null;
      }
      const previous = dataset[index - 1];
      if (previous && total.cummulative !== null) {
        total.cummulative += previous.cummulative;
      }
      const shiftedDate = new Date(day);
      shiftedDate.setDate(shiftedDate.getDate() + 1);
      shiftedDate.setHours(0, 0, 0, 0);
      dataset.push({ date: shiftedDate, cummulative: total.cummulative, expected: total.expected, activeDevices });
    });
    return dataset;
  };

  const splitByProperty = (array, property, productionUnit) => Object.values(
    array.filter((item) => item.productionUnit === productionUnit).reduce((acc, item) => {
      const key = item[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {}),
  );

  const engineHoursDataSeries = useMemo(() => ({
    series: [
      ...splitByProperty(chartItems, 'category', 'engineHours').map((category) => ({
        type: 'line',
        yAxisId: 'engineHours',
        label: t(`category${category[0].category.replace(/^\w/, (c) => c.toUpperCase())}`),
        data: dailyTotal(category, 'engineHours').map((item) => (item.cummulative ? formatMsecToHours(item.cummulative) : null)),
        valueFormatter: (v) => formatNumericHours(formatHoursToMsec(v), t),
        highlightScope: { highlighted: 'series', faded: 'global' },
        showMark: false,
        area: true,
        stack: 'categories',
        showLegend: true,
      })),
      {
        type: 'line',
        yAxisId: 'engineHours',
        label: t('reportEngineHours'),
        color: palette.charts.primary,
        data: dailyTotal(chartItems, 'engineHours', 'engineHours').map((item) => (item.cummulative ? formatMsecToHours(item.cummulative) : null)),
        valueFormatter: (v) => formatNumericHours(formatHoursToMsec(v), t),
        highlightScope: { highlighted: 'series', faded: 'global' },
        showMark: false,
      },
      {
        type: 'line',
        yAxisId: 'engineHours',
        label: t('reportExpectedValue'),
        color: palette.charts.secondary,
        data: dailyTotal(chartItems, 'engineHours', 'engineHours').map((item) => (item.expected ? formatMsecToHours(item.expected) : null)),
        valueFormatter: (v) => formatNumericHours(formatHoursToMsec(v), t),
        highlightScope: { highlighted: 'series', faded: 'global' },
        showMark: false,
      },
      {
        type: 'line',
        yAxisId: 'activeDevices',
        label: t('statisticsActiveDevices'),
        color: palette.charts.warning,
        data: dailyTotal(chartItems, 'engineHours', 'engineHours').map((item) => (item.expected ? item.activeDevices : null)),
        /* valueFormatter: (v) => formatNumericHours(formatHoursToMsec(v), t), */
        highlightScope: { highlighted: 'series', faded: 'global' },
        showMark: false,
      },
    ],
    xAxis: [
      {
        id: 'date',
        data: dailyTotal(chartItems, 'engineHours').map((item) => item.date),
        scaleType: 'time',
        tickLabelInterval: () => true,
        valueFormatter: (date, context) => {
          if (context.location === 'tick') {
            return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          }
          return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
        },
      },
    ],
    yAxis: [
      {
        id: 'engineHours',
        scaleType: 'linear',
        tickNumber: 8,
      },
      {
        id: 'activeDevices',
        scaleType: 'linear',
      },
    ],
    chartsXAxis: [
      <ChartsXAxis
        key="engineHoursDevice"
        position="bottom"
        axisId="date"
        tickInterval={() => true}
        tickLabelStyle={{
          fontSize: '10px',
          textAnchor: 'middle',
          angle: 0,
        }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateY(22px)',
          },
        }}
      />,
    ],
    chartsYAxis: [
      <ChartsYAxis
        key="engineHours"
        label={t('sharedHours').charAt(0).toUpperCase() + t('sharedHours').slice(1)}
        position="left"
        axisId="engineHours"
        tickLabelStyle={{ fontSize: 10 }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateX(-15px)',
          },
        }}
      />,
      <ChartsYAxis
        key="activeDevices"
        label={t('statisticsActiveDevices').charAt(0).toUpperCase() + t('statisticsActiveDevices').slice(1)}
        position="right"
        axisId="activeDevices"
        tickLabelStyle={{ fontSize: 10 }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateX(0px)',
          },
        }}
      />,
    ],
    chartAxisHighLight: <ChartsAxisHighlight x="line" y="none" />,

  }), [chartItems]);
  const pumpedMaterialDataSeries = useMemo(() => ({
    series: [
      {
        type: 'line',
        yAxisId: 'pumpedMaterial',
        label: t('reportPumpedMaterial'),
        color: palette.primary.main,
        data: dailyTotal(chartItems, 'pumpedMaterial').map((item) => item.cummulative),
        valueFormatter: (v) => `${v} ${t('sharedCubicMeterAbbreviation')}`,
        highlightScope: { highlighted: 'series', faded: 'global' },
        showMark: false,
        area: true,
      },
      {
        type: 'line',
        yAxisId: 'pumpedMaterial',
        label: t('reportExpectedValue'),
        color: palette.charts.secondary,
        data: dailyTotal(chartItems, 'pumpedMaterial').map((item) => item.expected),
        valueFormatter: (v) => `${v} ${t('sharedCubicMeterAbbreviation')}`,
        highlightScope: { highlighted: 'series', faded: 'global' },
        showMark: false,
      },
    ],
    xAxis: [
      {
        id: 'date',
        data: dailyTotal(chartItems, 'hours').map((item) => item.date),
        scaleType: 'time',
        tickLabelInterval: () => true,
        valueFormatter: (date, context) => {
          if (context.location === 'tick') {
            return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          }
          return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
        },
      },
    ],
    yAxis: [
      {
        id: 'pumpedMaterial',
        scaleType: 'linear',
        tickNumber: 8,
      },
    ],
    chartsXAxis: [
      <ChartsXAxis
        key="pumpedMaterialDate"
        position="bottom"
        axisId="date"
        tickInterval={() => true}
        tickLabelStyle={{
          fontSize: '10px',
          textAnchor: 'middle',
          angle: 0,
        }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateY(22px)',
          },
        }}
      />,
    ],
    chartsYAxis: [
      <ChartsYAxis
        key="pumpedMaterial"
        label={t('sharedCubicMeterAbbreviation')}
        position="left"
        axisId="pumpedMaterial"
        tickLabelStyle={{ fontSize: 10 }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateX(-15px)',
          },
        }}
      />,
    ],
    chartAxisHighLight: <ChartsAxisHighlight x="line" y="none" />,

  }), [chartItems]);

  const engineHoursDataSeriesAverage = useMemo(() => ({
    series: [
      ...splitByProperty(chartItems, 'category', 'engineHours').map((category) => ({
        type: 'line',
        yAxisId: 'engineHours',
        label: t(`category${category[0].category.replace(/^\w/, (c) => c.toUpperCase())}`),
        data: dailyTotal(category, 'engineHours').map((item) => (item.cummulative ? formatMsecToHours(item.cummulative / item.activeDevices) : null)),
        valueFormatter: (v) => formatNumericHours(formatHoursToMsec(v), t),
        highlightScope: { highlighted: 'series', faded: 'global' },
        showMark: false,
        area: true,
        stack: 'categories',
        showLegend: true,
      })),
      {
        type: 'line',
        yAxisId: 'engineHours',
        label: t('reportEngineHours'),
        color: palette.charts.primary,
        data: dailyTotal(chartItems, 'engineHours', 'engineHours').map((item) => (item.cummulative ? formatMsecToHours(item.cummulative / item.activeDevices) : null)),
        valueFormatter: (v) => formatNumericHours(formatHoursToMsec(v), t),
        highlightScope: { highlighted: 'series', faded: 'global' },
        showMark: false,
      },
      {
        type: 'line',
        yAxisId: 'engineHours',
        label: t('reportExpectedValue'),
        color: palette.charts.secondary,
        data: dailyTotal(chartItems, 'engineHours', 'engineHours').map((item) => (item.expected ? formatMsecToHours(item.expected / item.activeDevices) : null)),
        valueFormatter: (v) => formatNumericHours(formatHoursToMsec(v), t),
        highlightScope: { highlighted: 'series', faded: 'global' },
        showMark: false,
      },
      {
        type: 'line',
        yAxisId: 'activeDevices',
        label: t('statisticsActiveDevices'),
        color: palette.charts.warning,
        data: dailyTotal(chartItems, 'engineHours', 'engineHours').map((item) => (item.expected ? item.activeDevices : null)),
        /* valueFormatter: (v) => formatNumericHours(formatHoursToMsec(v), t), */
        highlightScope: { highlighted: 'series', faded: 'global' },
        showMark: false,
      },
    ],
    xAxis: [
      {
        id: 'date',
        data: dailyTotal(chartItems, 'engineHours').map((item) => item.date),
        scaleType: 'time',
        tickLabelInterval: () => true,
        valueFormatter: (date, context) => {
          if (context.location === 'tick') {
            return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          }
          return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
        },
      },
    ],
    yAxis: [
      {
        id: 'engineHours',
        scaleType: 'linear',
        tickNumber: 8,
      },
      {
        id: 'activeDevices',
        scaleType: 'linear',
      },
    ],
    chartsXAxis: [
      <ChartsXAxis
        key="engineHoursDevice"
        position="bottom"
        axisId="date"
        tickInterval={() => true}
        tickLabelStyle={{
          fontSize: '10px',
          textAnchor: 'middle',
          angle: 0,
        }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateY(22px)',
          },
        }}
      />,
    ],
    chartsYAxis: [
      <ChartsYAxis
        key="engineHours"
        label={t('sharedHours').charAt(0).toUpperCase() + t('sharedHours').slice(1)}
        position="left"
        axisId="engineHours"
        tickLabelStyle={{ fontSize: 10 }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateX(-15px)',
          },
        }}
      />,
      <ChartsYAxis
        key="activeDevices"
        label={t('statisticsActiveDevices').charAt(0).toUpperCase() + t('statisticsActiveDevices').slice(1)}
        position="right"
        axisId="activeDevices"
        tickLabelStyle={{ fontSize: 10 }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateX(0px)',
          },
        }}
      />,
    ],
    chartAxisHighLight: <ChartsAxisHighlight x="line" y="none" />,

  }), [chartItems]);
  const pumpedMaterialDataSeriesAverage = useMemo(() => ({
    series: [
      {
        type: 'line',
        yAxisId: 'pumpedMaterial',
        label: t('reportPumpedMaterial'),
        color: palette.primary.main,
        data: dailyTotal(chartItems, 'pumpedMaterial').map((item) => item.cummulative),
        valueFormatter: (v) => `${v} ${t('sharedCubicMeterAbbreviation')}`,
        highlightScope: { highlighted: 'series', faded: 'global' },
        showMark: false,
        area: true,
      },
      {
        type: 'line',
        yAxisId: 'pumpedMaterial',
        label: t('reportExpectedValue'),
        color: palette.charts.secondary,
        data: dailyTotal(chartItems, 'pumpedMaterial').map((item) => item.expected),
        valueFormatter: (v) => `${v} ${t('sharedCubicMeterAbbreviation')}`,
        highlightScope: { highlighted: 'series', faded: 'global' },
        showMark: false,
      },
    ],
    xAxis: [
      {
        id: 'date',
        data: dailyTotal(chartItems, 'hours').map((item) => item.date),
        scaleType: 'time',
        tickLabelInterval: () => true,
        valueFormatter: (date, context) => {
          if (context.location === 'tick') {
            return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          }
          return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
        },
      },
    ],
    yAxis: [
      {
        id: 'pumpedMaterial',
        scaleType: 'linear',
        tickNumber: 8,
      },
    ],
    chartsXAxis: [
      <ChartsXAxis
        key="pumpedMaterialDate"
        position="bottom"
        axisId="date"
        tickInterval={() => true}
        tickLabelStyle={{
          fontSize: '10px',
          textAnchor: 'middle',
          angle: 0,
        }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateY(22px)',
          },
        }}
      />,
    ],
    chartsYAxis: [
      <ChartsYAxis
        key="pumpedMaterial"
        label={t('sharedCubicMeterAbbreviation')}
        position="left"
        axisId="pumpedMaterial"
        tickLabelStyle={{ fontSize: 10 }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateX(-15px)',
          },
        }}
      />,
    ],
    chartAxisHighLight: <ChartsAxisHighlight x="line" y="none" />,

  }), [chartItems]);

  const totalPercentageAchieved = () => {
    if (chartItems.length < 1) {
      return 0;
    }
    return ((chartItems.reduce((acc, item) => acc + getProduction(item) / item.goal, 0) / chartItems.length) * 100).toFixed(1);
  };

  const devicesCompliancePercentage = () => {
    if (chartItems.length < 1) {
      return 0;
    }
    const devices = chartItems.filter((item) => getProduction(item) / item.goal >= 0.9);
    return ((devices.length / chartItems.length) * 100).toFixed(1);
  };

  const handleSubmit = useCatch(async ({ from, to, branchIds, projectIds, constructionIds }) => {
    setLoading(true);
    let groupIds = [];
    if (constructionIds.length > 0) {
      groupIds = constructionIds;
    } else if (projectIds.length > 0) {
      groupIds = projectIds;
    } else if (branchIds.length > 0) {
      groupIds = branchIds;
    }
    try {
      const query = new URLSearchParams({ from, to, tablename: 'heavy_duty' });
      groupIds.forEach((groupId) => query.append('groupId', groupId));
      const response = await fetch(`/api/express/analytics/goals?${query}`);
      if (response.ok) {
        const data = await response.json();
        setItems(data);
        setDateRange({ from, to });
        setSelectedItems(data.map((item) => item.deviceid));
      } else {
        const data = await response.text();
        throw Error(data);
      }
    } finally {
      setLoading(false);
    }
  });

  const handleCategoryFilter = (category) => {
    setCategory(category);
  };

  const renderCharts = (category, t) => {
    const isStationary = category === 'stationaryConcretePump';
    const chartData = isStationary
      ? { title: t('reportPumpedMaterial'), series: pumpedMaterialDataSeries }
      : { title: t('reportEngineHours'), series: engineHoursDataSeries };

    const averageData = isStationary
      ? { title: t('reportPumpedMaterialDeviceAverage'), series: pumpedMaterialDataSeriesAverage }
      : { title: t('reportEngineHoursDeviceAverage'), series: engineHoursDataSeriesAverage };

    return (
      <>
        {/* Main Chart */}
        <Grid item container xs direction="column">
          <Grid item>
            <Typography variant="h5">{chartData.title}</Typography>
          </Grid>
          <Grid item xs height={260}>
            <CustomChart {...chartData.series} margin={{ top: 80, right: 20, bottom: 20, left: 55 }} />
          </Grid>
        </Grid>

        {/* Average Chart */}
        <Grid item container xs direction="column">
          <Grid item>
            <Typography variant="h5">{averageData.title}</Typography>
          </Grid>
          <Grid item xs height={260}>
            <CustomChart {...averageData.series} margin={{ top: 80, right: 20, bottom: 20, left: 55 }} />
          </Grid>
        </Grid>
      </>
    );
  };

  const formatValue = (item, key) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatTime(item[key], 'minutes', hours12);
      case 'duration':
        return formatHours(item[key]);
      case 'engineHours':
      case 'hours':
        return formatNumericHours(item[key], t);
      case 'spentFuel':
      case 'fuel_consumption':
        return formatVolume(item[key] || 0, volumeUnit, t, 0);
      case 'pumpedMaterial':
        return `${Math.round(item.productionValue) || 0} ${t('sharedCubicMeterAbbreviation')}`;
      case 'distance':
        return formatDistance(item[key], distanceUnit, t);
      case 'type':
        return t(prefixString('event', item[key]));
      case 'attributes':
        switch (item.type) {
          case 'alarm':
            return t(prefixString('alarm', item.attributes.alarm));
          case 'deviceOverspeed':
            return formatSpeed(item.attributes.speed, speedUnit, t);
          case 'deviceFuelIncrease':
            return formatVolume(item.attributes.volume, volumeUnit, t, 0);
          default:
            return '';
        }
      default:
        return item[key];
    }
  };

  const rowShimmer = (cols) => {
    const callback = () => (
      <VirtualizedTableShimmer columns={cols.length + 1} compact />
    );
    return callback;
  };
  const top5Headers = (key) => {
    const callback = () => (
      <TableRow className={classes.tableHeader}>
        <TableCell sx={{ p: 0, pl: 2 }}>{t('sharedDevice')}</TableCell>
        <TableCell sx={{ p: 0, pl: 2 }}>{t(key)}</TableCell>
        {top5Columns.map((key) => (<TableCell key={key} sx={{ px: 1 }}>{t(columnsMap.get(key))}</TableCell>))}
      </TableRow>
    );
    return callback;
  };
  const top5RowContent = (key, valueGetter) => {
    const callback = (_index, row) => {
      const { deviceid } = row;
      row[key] = row[valueGetter];
      return (
        <>
          <TableCell sx={{ px: 2, py: 0, minWidth: { xs: 100 } }}>{devices[deviceid].name}</TableCell>
          <TableCell sx={{ px: 2, py: 0, minWidth: { xs: 100 } }}>{formatValue(row, key)}</TableCell>
          {top5Columns.map((key) => (
            <TableCell key={key} sx={{ p: 0, px: 1, textAlign: 'start', minWidth: { xs: 80 } }}>
              {formatValue(row, key)}
            </TableCell>
          ))}
        </>
      );
    };
    return callback;
  };

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle']}>
      <div className={classes.container}>
        <div className={classes.header}>
          <CorporateReportFilter
            handleSubmit={handleSubmit}
            ignoreDevice
            includeGroups={['branch', 'project', 'construction']}
            deviceType="heavy-duty"
            isDaily
            showOnly
            handleFilter={handleCategoryFilter}
            loading={loading}
          >
            {/* <ColumnSelect columns={columns} setColumns={setColumns} columnsArray={columnsArray} /> */}
          </CorporateReportFilter>
        </div>
        <div className={classes.containerMain}>
          <Grid container sx={{ flex: 1, px: 2 }} spacing={2}>
            {/* LEFT COLUMN */}
            <Grid item container xs={12} md={5} direction="column" spacing={2} sx={{ height: 640 }}>
              {/* TABLA */}
              <Grid item sx={{ width: '100%' }} xs>
                <Box sx={{ height: 492 }}>
                  <CustomDataGrid
                    columns={category === 'stationaryConcretePump' ? pumpedMaterialColumns : engineHoursColumns}
                    rows={showItems}
                    checkboxSelection
                    selections={selectedItems}
                    setSelections={setSelectedItems}
                  />
                </Box>
              </Grid>
              {/* TOTALES */}
              <Grid item container columnSpacing={2} direction="row" xs="auto">
                <TotalCard title={t('reportPercentageAchieved')} value={`${totalPercentageAchieved()}%`} width={4} />
                <TotalCard title={t('reportDevicesCompliancePercentage')} value={`${devicesCompliancePercentage()}%`} width={5} />
                <TotalCard title={t('reportTotalDevices')} value={`${chartItems.length}`} width={3} />
              </Grid>
            </Grid>
            {/* RIGHT COLUMN */}
            <Grid item container xs={12} md={7} direction="column" spacing={2} sx={{ height: 640 }}>
              {/* CHARTS */}
              {renderCharts(category, t)}
            </Grid>
            {/* TOP 5 */}
            <Grid item xs={12} container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{ bgcolor: palette.primary.main, color: palette.primary.contrastText, px: 2, my: 1, borderRadius: 4 }}
                >
                  {`Top 5 ${t('reportPumpedMaterial')}`}
                </Typography>
                <Box sx={{ height: '200px' }}>
                  <VirtualizedTable
                    columns={top5Headers('reportPumpedMaterial')}
                    rowContent={!loading && items.length > 0 ? top5RowContent('pumpedMaterial', 'productionValue') : rowShimmer([...Array(5)])}
                    rows={!loading && items.length > 0 ?
                      truncateItems(sortedItems(filterItems(chartItems, 'productionUnit', 'pumpedMaterial'), 'productionValue'), 5)
                      :
                      [...Array(5)]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{ bgcolor: palette.primary.main, color: palette.primary.contrastText, px: 2, my: 1, borderRadius: 4 }}
                >
                  {`Top 5 ${t('reportSpentFuel')}`}
                </Typography>
                <Box sx={{ height: '200px' }}>
                  <VirtualizedTable
                    columns={top5Headers('reportSpentFuel')}
                    rowContent={!loading && items.length > 0 ? top5RowContent('spentFuel', 'spentFuel') : rowShimmer([...Array(5)])}
                    rows={!loading && items.length > 0 ?
                      truncateItems(sortedItems([...chartItems], 'spentFuel'), 5)
                      :
                      [...Array(5)]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{ bgcolor: palette.primary.main, color: palette.primary.contrastText, px: 2, my: 1, borderRadius: 4 }}
                >
                  {`Top 5 ${t('reportEngineHours')}`}
                </Typography>
                <Box sx={{ height: '200px' }}>
                  <VirtualizedTable
                    columns={top5Headers('reportEngineHours')}
                    rowContent={!loading && items.length > 0 ? top5RowContent('engineHours', 'totalHours') : rowShimmer([...Array(5)])}
                    rows={!loading && items.length > 0 ?
                      truncateItems(sortedItems([...chartItems], 'totalHours'), 5)
                      :
                      [...Array(5)]}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </PageLayout>
  );
};

export default CorporateGoalsPage;

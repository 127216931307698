import { useDispatch, useSelector, connect } from 'react-redux';

import {
  geofencesActions, groupsActions, driversActions, maintenancesActions, calendarsActions, categoriesActions,
  reportsActions,
  sessionActions,
} from './store';
import { useEffectAsync } from './reactHelper';

const CachingController = () => {
  const authenticated = useSelector((state) => !!state.session.user);
  const dispatch = useDispatch();

  const loadGeofences = async () => {
    const response = await fetch('/api/geofences');
    if (response.ok) {
      dispatch(geofencesActions.update(await response.json()));
    } else {
      throw Error(await response.text());
    }
  };

  const loadGroups = async () => {
    const response = await fetch('/api/groups');
    if (response.ok) {
      // NEW
      const data = await response.json();
      const branches = data.filter((group) => group.attributes.type === 'branch').map((group) => (group.id));
      dispatch(groupsActions.update(data));
      dispatch(reportsActions.updateBranchIds(branches));
    } else {
      throw Error(await response.text());
    }
  };

  const loadDrivers = async () => {
    const response = await fetch('/api/drivers');
    if (response.ok) {
      dispatch(driversActions.update(await response.json()));
    } else {
      throw Error(await response.text());
    }
  };

  const loadMaintenances = async () => {
    const response = await fetch('/api/maintenance');
    if (response.ok) {
      dispatch(maintenancesActions.update(await response.json()));
    } else {
      throw Error(await response.text());
    }
  };

  const loadCalendars = async () => {
    const response = await fetch('/api/calendars');
    if (response.ok) {
      dispatch(calendarsActions.update(await response.json()));
    } else {
      throw Error(await response.text());
    }
  };

  const loadCategories = async () => {
    const response = await fetch('/api/express/operation/categories');
    if (response.ok) {
      dispatch(categoriesActions.update(await response.json()));
    } else {
      throw Error(await response.text());
    }
  };

  useEffectAsync(async () => {
    if (authenticated) {
      await Promise.all([
        loadGeofences(),
        loadGroups(),
        loadDrivers(),
        loadMaintenances(),
        loadCalendars(),
        loadCategories(),
      ]);
      dispatch(sessionActions.updateAllCached());
    }
  }, [authenticated]);

  return null;
};

export default connect()(CachingController);

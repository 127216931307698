import { useMemo } from 'react';

export default (t) => useMemo(() => ({
  hours: {
    name: t('positionHours'),
    type: 'number',
    dataType: 'hours',
  },
  /* totalDistance: {
    name: t('deviceTotalDistance'),
    type: 'number',
    dataType: 'distance',
  }, */
}), [t]);

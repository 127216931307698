import React, { useEffect, useState } from 'react';
import {
  FormControl, InputLabel, Select, MenuItem, Button, TextField, Typography,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';
import { devicesActions, reportsActions } from '../../store';
import SplitButton from '../../common/components/SplitButton';
import SelectField from '../../common/components/SelectField';
import { useRestriction } from '../../common/util/permissions';
import deviceCategories from '../../common/util/deviceCategories';
import palette from '../../common/theme/palette';

const CorporateReportFilter = ({
  children, handleSubmit, handleSchedule, showOnly,
  ignoreDevice, ignoreCategory, ignorePeriod, ignoreBranch, ignoreProject, ignoreConstruction, ignoreSubmit,
  multiDevice, includeGroups, deviceType, isDaily = false, handleFilter = false,
  loading,
}) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const readonly = useRestriction('readonly');

  const devices = useSelector((state) => state.devices.items);
  const groups = useSelector((state) => state.groups.items);

  const deviceId = useSelector((state) => state.devices.selectedId);
  const deviceIds = useSelector((state) => state.devices.selectedIds);
  const groupIds = useSelector((state) => state.reports.groupIds);
  const period = useSelector((state) => state.reports.period);
  const from = useSelector((state) => state.reports.from);
  const to = useSelector((state) => state.reports.to);
  const button = useSelector((state) => state.reports.button);

  const allCached = useSelector((state) => state.session.allCached);

  // eslint-disable-next-line no-unused-vars
  const branchIds = useSelector((state) => state.reports.branchIds);
  // eslint-disable-next-line no-unused-vars
  const projectIds = useSelector((state) => state.reports.projectIds);
  // eslint-disable-next-line no-unused-vars
  const constructionIds = useSelector((state) => state.reports.constructionIds);

  const [description, setDescription] = useState();
  const [calendarId, setCalendarId] = useState();

  const scheduleDisabled = button === 'schedule' && (!description || !calendarId);
  const disabled = (!ignoreDevice && !deviceId && !deviceIds.length && !groupIds.length) || scheduleDisabled;

  const [category, setCategory] = useState('');
  const formatDate = (date) => {
    if (isDaily) {
      return date += 'T00:00:00';
    }
    return date;
  };

  const handleClick = (type) => {
    if (type === 'schedule') {
      handleSchedule(deviceIds, groupIds, {
        description,
        calendarId,
        attributes: {},
      });
    } else {
      let selectedFrom;
      let selectedTo;
      switch (period) {
        case 'today':
          selectedFrom = moment().startOf('day');
          selectedTo = moment().endOf('day');
          break;
        case 'yesterday':
          selectedFrom = moment().subtract(1, 'day').startOf('day');
          selectedTo = moment().subtract(1, 'day').endOf('day');
          break;
        case 'thisWeek':
          selectedFrom = moment().startOf('week');
          selectedTo = moment().endOf('week');
          break;
        case 'previousWeek':
          selectedFrom = moment().subtract(1, 'week').startOf('week');
          selectedTo = moment().subtract(1, 'week').endOf('week');
          break;
        case 'thisMonth':
          selectedFrom = moment().startOf('month');
          selectedTo = moment().endOf('month');
          break;
        case 'previousMonth':
          selectedFrom = moment().subtract(1, 'month').startOf('month');
          selectedTo = moment().subtract(1, 'month').endOf('month');
          break;
        default:
          selectedFrom = moment(from, moment.HTML5_FMT.DATETIME_LOCAL);
          selectedTo = moment(to, moment.HTML5_FMT.DATETIME_LOCAL);
          break;
      }

      handleSubmit({
        deviceId,
        deviceIds,
        groupIds,
        branchIds,
        projectIds,
        constructionIds,
        from: selectedFrom.toISOString(),
        to: selectedTo.toISOString(),
        calendarId,
        type,
      });
    }
  };

  useEffect(() => {
    const newProjectIds = projectIds.filter((projectId) => branchIds.includes(groups[projectId].groupId));
    dispatch(reportsActions.updateProjectIds(newProjectIds));
  }, [branchIds]);

  useEffect(() => {
    const newConstructionIds = constructionIds.filter((constructionId) => projectIds.includes(groups[constructionId].groupId));
    dispatch(reportsActions.updateConstructionIds(newConstructionIds));
  }, [projectIds]);

  useEffect(() => {
    if (handleFilter) {
      handleFilter(category);
    }
  }, [category]);

  useEffect(() => {
    if (allCached && handleSubmit) {
      dispatch(reportsActions.updatePeriod('thisMonth'));
      dispatch(reportsActions.updateFrom(moment().startOf('month').toISOString()));
      dispatch(reportsActions.updateTo(moment().endOf('month').toISOString()));
      handleSubmit({
        deviceId,
        deviceIds,
        groupIds,
        branchIds,
        projectIds,
        constructionIds,
        from: moment().startOf('month').toISOString(),
        to: moment().endOf('month').toISOString(),
        calendarId,
        type: 'json',
      });
    }
  }, [allCached]);

  return (
    <div className={classes.filter}>
      {!ignoreDevice && (
        <div className={classes.filterItem}>
          <FormControl fullWidth>
            <InputLabel>{t(multiDevice ? 'deviceTitle' : 'reportDevice')}</InputLabel>
            <Select
              label={t(multiDevice ? 'deviceTitle' : 'reportDevice')}
              value={multiDevice ? deviceIds : deviceId || ''}
              onChange={(e) => dispatch(multiDevice ? devicesActions.selectIds(e.target.value) : devicesActions.selectId(e.target.value))}
              multiple={multiDevice}
            >
              {Object.values(devices).filter((item) => {
                if (deviceType) {
                  if (item.attributes.deviceType === deviceType) {
                    return true;
                  }
                  return false;
                }
                return true;
              }).sort((a, b) => a.name.localeCompare(b.name)).map((device) => (
                <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      {includeGroups && (
        <>
          {!ignoreBranch && (
            <div className={classes.filterItem}>
              <FormControl fullWidth>
                <InputLabel>{t('reportBranch')}</InputLabel>
                <Select
                  label={t('reportBranch')}
                  value={branchIds}
                  onChange={(e) => dispatch(reportsActions.updateBranchIds(e.target.value))}
                  multiple
                >
                  {Object.values(groups).filter((item) => {
                    if (item.attributes.type === 'branch') {
                      return true;
                    }
                    return false;
                  }).sort((a, b) => a.name.localeCompare(b.name)).map((group) => (
                    <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {!ignoreProject && (
            <div className={classes.filterItem}>
              <FormControl fullWidth>
                <InputLabel>{t('reportProject')}</InputLabel>
                <Select
                  label={t('reportProject')}
                  value={projectIds}
                  onChange={(e) => dispatch(reportsActions.updateProjectIds(e.target.value))}
                  multiple
                >
                  {Object.values(groups).filter((item) => {
                    if (item.attributes.type === 'project') {
                      if (branchIds.includes(item.groupId)) {
                        return true;
                      }
                    }
                    return false;
                  }).sort((a, b) => a.name.localeCompare(b.name)).map((group) => (
                    <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {!ignoreConstruction && (
            <div className={classes.filterItem}>
              <FormControl fullWidth>
                <InputLabel>{t('reportConstruction')}</InputLabel>
                <Select
                  label={t('reportConstruction')}
                  value={constructionIds}
                  onChange={(e) => dispatch(reportsActions.updateConstructionIds(e.target.value))}
                  multiple
                >
                  {Object.values(groups).filter((item) => {
                    if (item.attributes.type === 'construction') {
                      if (projectIds.includes(item.groupId)) {
                        return true;
                      }
                    }
                    return false;
                  }).sort((a, b) => a.name.localeCompare(b.name)).map((group) => (
                    <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {!ignoreCategory && (
            <div className={classes.filterItem}>
              <SelectField
                fullWidth
                value={category}
                emptyValue=""
                onChange={(event) => setCategory(event.target.value)}
                data={deviceCategories.filter((category) => (deviceType ? category.type === deviceType : true)).map((category) => ({
                  id: category.label,
                  name: t(`category${category.label.replace(/^\w/, (c) => c.toUpperCase())}`),
                }))}
                label={t('deviceCategory')}
              />
            </div>
          )}
        </>
      )}
      {!ignorePeriod && (
        button !== 'schedule' ? (
          <>
            <div className={classes.filterItem}>
              <FormControl fullWidth>
                <InputLabel>{t('reportPeriod')}</InputLabel>
                <Select label={t('reportPeriod')} value={period !== 'today' ? period : ''} onChange={(e) => dispatch(reportsActions.updatePeriod(e.target.value))}>
                  {/* <MenuItem value="today">{t('reportToday')}</MenuItem> */}
                  <MenuItem value="yesterday">{t('reportYesterday')}</MenuItem>
                  <MenuItem value="thisWeek">{t('reportThisWeek')}</MenuItem>
                  <MenuItem value="previousWeek">{t('reportPreviousWeek')}</MenuItem>
                  <MenuItem value="thisMonth">{t('reportThisMonth')}</MenuItem>
                  <MenuItem value="previousMonth">{t('reportPreviousMonth')}</MenuItem>
                  <MenuItem value="custom">{t('reportCustom')}</MenuItem>
                </Select>
              </FormControl>
            </div>
            {period === 'custom' && (
              <div className={classes.filterItem}>
                <TextField
                  label={t('reportFrom')}
                  type={isDaily ? 'date' : 'datetime-local'}
                  value={isDaily ? from.split('T')[0] : from}
                  onChange={(e) => dispatch(reportsActions.updateFrom(formatDate(e.target.value)))}
                  fullWidth
                />
              </div>
            )}
            {period === 'custom' && (
              <div className={classes.filterItem}>
                <TextField
                  label={t('reportTo')}
                  type={isDaily ? 'date' : 'datetime-local'}
                  value={isDaily ? to.split('T')[0] : to}
                  onChange={(e) => dispatch(reportsActions.updateTo(formatDate(e.target.value)))}
                  fullWidth
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className={classes.filterItem}>
              <TextField
                value={description || ''}
                onChange={(event) => setDescription(event.target.value)}
                label={t('sharedDescription')}
                fullWidth
              />
            </div>
            <div className={classes.filterItem}>
              <SelectField
                value={calendarId || 0}
                onChange={(event) => setCalendarId(Number(event.target.value))}
                endpoint="/api/calendars"
                label={t('sharedCalendar')}
                fullWidth
              />
            </div>
          </>
        )
      )}
      {children}
      {!ignoreSubmit && (
        <div className={classes.filterItem}>
          {showOnly ? (
            <Button
              fullWidth
              variant={disabled || loading ? 'contained' : 'outlined'}
              color="secondary"
              disabled={disabled || loading}
              onClick={() => handleClick('json')}
            >
              {
                loading ?
                  <CircularProgress size={24} sx={{ color: palette.colors.primary }} />
                  :
                  <Typography variant="button" noWrap>{t('reportShow')}</Typography>
              }
            </Button>
          ) : (
            <SplitButton
              fullWidth
              variant="outlined"
              color="secondary"
              disabled={disabled}
              onClick={handleClick}
              selected={button}
              setSelected={(value) => dispatch(reportsActions.updateButton(value))}
              options={readonly ? {
                json: t('reportShow'),
                export: t('reportExport'),
                mail: t('reportEmail'),
              } : {
                json: t('reportShow'),
                export: t('reportExport'),
                mail: t('reportEmail'),
                schedule: t('reportSchedule'),
              }}
            />
          )}
        </div>
      )}

    </div>
  );
};

export default CorporateReportFilter;

import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { prefixString } from '../common/util/stringUtils';
import EditItemView from './components/EditItemView';
import EditAttributesAccordion from './components/EditAttributesAccordion';
import { useAttributePreference } from '../common/util/preferences';
import {
  speedFromKnots, speedToKnots, distanceFromMeters, distanceToMeters,
} from '../common/util/converter';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';
import useMaintenanceTypes from '../common/attributes/useMaintenanceTypes';
import LinkField from '../common/components/LinkField';
import MaintenanceCategoryDetails from './components/MaintenanceCategoryDetails';
import SelectField from '../common/components/SelectField';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const MaintenancePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const maintenanceTypes = useMaintenanceTypes(t);

  const [item, setItem] = useState();
  const [labels, setLabels] = useState({ interval: '' });
  const [linkedCategories, setLinkedCategories] = useState();

  const speedUnit = useAttributePreference('speedUnit', 'kn');
  const distanceUnit = useAttributePreference('distanceUnit', 'km');

  const convertToList = (attributes) => {
    const otherList = [];
    Object.keys(attributes).forEach((key) => {
      const value = attributes[key];
      if (value.type === 'number') {
        otherList.push({ key, name: value.name, type: value.type });
      }
    });
    return otherList;
  };

  const onMaintenanceTypeChange = (event) => {
    const newValue = event.target.value;
    setItem({
      ...item, type: newValue, interval: 0,
    });

    const attribute = maintenanceTypes[newValue];
    if (attribute && attribute.dataType) {
      switch (attribute.dataType) {
        case 'distance':
          setLabels({ ...labels, interval: t(prefixString('shared', distanceUnit)) });
          break;
        case 'speed':
          setLabels({ ...labels, interval: t(prefixString('shared', speedUnit)) });
          break;
        default:
          setLabels({ ...labels, interval: null });
          break;
      }
    } else {
      setLabels({ ...labels, interval: null });
    }
  };

  const rawToValue = (value) => {
    const attribute = maintenanceTypes[item.type];
    if (attribute && attribute.dataType) {
      switch (attribute.dataType) {
        case 'speed':
          return speedFromKnots(value, speedUnit);
        case 'distance':
          return distanceFromMeters(value, distanceUnit);
        default:
          return value;
      }
    }
    return value;
  };

  const valueToRaw = (value) => {
    const attribute = maintenanceTypes[item.type];
    if (attribute && attribute.dataType) {
      switch (attribute.dataType) {
        case 'speed':
          return speedToKnots(value, speedUnit);
        case 'distance':
          return distanceToMeters(value, distanceUnit);
        default:
          return value;
      }
    }
    return value;
  };

  const validate = () => item && item.name && item.type && item.interval;

  const onLinkChange = (value) => {
    setLinkedCategories(value);
  };

  return (
    <EditItemView
      endpoint="express/maintenances"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'sharedMaintenance']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
              />
              <FormControl>
                <InputLabel>{t('sharedType')}</InputLabel>
                <Select
                  label={t('sharedType')}
                  value={item.type || ''}
                  onChange={onMaintenanceTypeChange}
                >
                  {convertToList(maintenanceTypes).map(({ key, name }) => (
                    <MenuItem key={key} value={key}>{name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                type="number"
                value={rawToValue(item.interval) || ''}
                onChange={(event) => setItem({ ...item, interval: valueToRaw(event.target.value) })}
                label={labels.interval ? `${t('maintenanceInterval')} (${labels.interval})` : t('maintenanceInterval')}
              />
              <TextField
                type="number"
                value={rawToValue(item.acceptanceThreshold) || ''}
                onChange={(event) => setItem({ ...item, acceptanceThreshold: valueToRaw(event.target.value) })}
                label={labels.interval ? `${t('maintenanceAcceptanceThreshold')} (${labels.interval})` : t('maintenanceAcceptanceThreshold')}
              />
              <SelectField
                value={item.maintenanceId || 0}
                onChange={(event) => setItem({ ...item, maintenanceId: Number(event.target.value) })}
                endpoint="/api/express/maintenances"
                label={t('maintenanceComplimentary')}
                excludeFunction={(it) => (it.id !== item.id) && (it.maintenanceId !== item.id) && (it.type === item.type) && (it.interval < item.interval)}
              />
              {item.id &&
                (
                  <>
                    <LinkField
                      endpointAll="/api/express/operation/categories"
                      endpointLinked={`/api/express/maintenances/${item.id}/categories`}
                      baseId={item.id}
                      keyBase="maintenanceId"
                      keyLink="categoryId"
                      label={t('deviceCategory')}
                      titleGetter={(it) => t(`category${it.name.replace(/^\w/, (c) => c.toUpperCase())}`)}
                      permissionUrl="/api/express/permissions"
                      linkedCallback={onLinkChange}
                    />
                    {
                      linkedCategories && (
                        <>
                          <Typography variant="subtitle1">
                            {t('maintenanceCategoryDetails')}
                          </Typography>
                          <div>
                            {
                              linkedCategories.map((category, index) => (
                                <MaintenanceCategoryDetails key={category.id} index={index} category={category} />
                              ))
                            }
                          </div>
                        </>
                      )
                    }
                  </>
                )}
            </AccordionDetails>
          </Accordion>
          <EditAttributesAccordion
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{}}
          />
        </>
      )}
    </EditItemView>
  );
};

export default MaintenancePage;

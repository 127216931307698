import React, { useMemo, useState } from 'react';
import {
  Avatar, Box, Button, List, ListItem, Stack, Tooltip, tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
/* import PrintIcon from '@mui/icons-material/Print'; */
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import useReportStyles from './common/useReportStyles';
import CustomDataGrid from './components/CustomDataGrid';
import { formatRoundedNumericHours } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useEffectAsync } from '../reactHelper';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import palette from '../common/theme/palette';
import NavigationBreadCrumbs from './components/NavigationBreadCrumbs';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
  },
}));

const filterComplimentaryMaintenances = (maintenances) => maintenances.filter((m) => {
  if (maintenances.find((m2) => m2.maintenanceId === m.id)) {
    return false;
  }
  return true;
});

const maintenanceReportColumnsGenerator = (t, onRowSelect) => [
  /* DEVICE CATEGORY */
  {
    field: 'category',
    headerName: t('deviceCategory'),
    width: 90,
    valueGetter: (value) => t(`category${value.replace(/^\w/, (c) => c.toUpperCase())}`),
    renderCell: ({ row }) => <Avatar src={mapIcons[mapIconKey(row.category)]} variant="rounded" />,
  },
  /* DEVICE NAME */
  {
    field: 'device',
    headerName: t('sharedDevice'),
    type: 'string',
    valueGetter: (_value, row) => row.name.toUpperCase(),
    width: 200,
    renderCell: ({ row, value }) => (
      <Button onClick={() => onRowSelect(row)} variant="text">{value}</Button>
    ),
  },
  /* ENGINE HOURS */
  {
    field: 'engineHours',
    headerName: t('reportEngineHours'),
    type: 'number',
    valueGetter: (_value, row) => row.hours,
    valueFormatter: (v) => formatRoundedNumericHours(v, t),
    width: 120,
  },
  /* OVERDUE MAINTENANCES */
  {
    field: 'overdueMaintenances',
    type: 'number',
    headerName: t('overdueMaintenances'),
    width: 120,
    valueGetter: (_value, row) => {
      const overdueMaintenances = row.categoryMaintenances.filter((m) => m.needsMaintenance);
      return filterComplimentaryMaintenances(overdueMaintenances).length;
    },
    renderCell: ({ row, value }) => (
      <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        {
          value > 0 && (
            <LightTooltip
              title={(
                <List dense>
                  {filterComplimentaryMaintenances(row.categoryMaintenances
                    .filter((m) => m.needsMaintenance))
                    .map((m) => (
                      <ListItem key={m.id} sx={{ fontSize: 12 }}>
                        <Stack direction="row" gap={1} alignItems="center">
                          {`${m.name}:${formatRoundedNumericHours(m.nextMaintenance, t)}`}
                          {/* <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            sx={{ minWidth: 0, padding: 0 }}
                          >
                            <PrintIcon />
                          </Button> */}
                        </Stack>
                      </ListItem>
                    ))}
                </List>
              )}
              placement="left"
            >
              <Avatar sx={{ bgcolor: palette.error.main, height: 24, width: 24 }}>{value}</Avatar>
            </LightTooltip>
          )
        }
      </Box>
    ),
  },
  /* UPCOMING MAINTENANCES */
  {
    field: 'upcomingMaintenances',
    type: 'number',
    headerName: t('upcomingMaintenances'),
    width: 120,
    valueGetter: (_value, row) => {
      const upcomingMaintenances = row.categoryMaintenances.filter((m) => m.nextMaintenancePercentage > 75 && m.nextMaintenancePercentage < 100);
      return filterComplimentaryMaintenances(upcomingMaintenances).length;
    },
    renderCell: ({ row, value }) => (
      <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        {
          value > 0 && (
            <LightTooltip
              title={(
                <List dense>
                  {filterComplimentaryMaintenances(row.categoryMaintenances
                    .filter((m) => m.nextMaintenancePercentage > 75 && m.nextMaintenancePercentage < 100))
                    .map((m) => (
                      <ListItem key={m.id} sx={{ fontSize: 12 }}>
                        {`${m.name}:${formatRoundedNumericHours(m.nextMaintenance, t)}`}

                      </ListItem>
                    ))}
                </List>
              )}
              placement="left"
            >
              <Avatar sx={{ bgcolor: palette.warning.main, height: 24, width: 24 }}>{value}</Avatar>
            </LightTooltip>
          )
        }
      </Box>
    ),
  },
  /* PENDING ACTIVITIES */
  {
    field: 'pendingActivities',
    type: 'boolean',
    headerName: t('pendingActivities'),
    width: 150,
    valueGetter: (_value, row) => row.pendingActivities,
    renderCell: ({ value }) => (value ? <ReportProblemIcon color="error" /> : null),
  },
  { field: 'branch', type: 'string', valueGetter: (v) => v?.toUpperCase() || '', headerName: t('reportBranch'), width: 150 },
  { field: 'project', type: 'string', valueGetter: (v) => v?.toUpperCase() || '', headerName: t('reportProject'), width: 150 },
  { field: 'construction', type: 'string', valueGetter: (v) => v?.toUpperCase() || '', headerName: t('reportConstruction'), width: 150 },
];

const MaintenanceReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);

  const onRowSelect = async (row) => {
    navigate(`/reports/maintenances/device/${row.deviceId}`);
  };

  const maintenanceReportColumns = useMemo(() => maintenanceReportColumnsGenerator(t, onRowSelect), [t]);

  useEffectAsync(async () => {
    const response = await fetch('/api/express/maintenances/devices/status');
    if (response.ok) {
      setItems(await response.json());
    } else {
      throw Error(await response.text());
    }
  }, []);

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle']}>
      <div className={classes.header}>
        <NavigationBreadCrumbs
          urls={['/reports/maintenances']}
          labels={[t('reportMaintenances')]}
        />
      </div>
      <div className={classes.tableContainer}>
        <CustomDataGrid
          columns={maintenanceReportColumns}
          rows={items || []}
          disableColumnFilter={false}
          rowHeight={40}
        />
      </div>
    </PageLayout>
  );
};

export default MaintenanceReportPage;

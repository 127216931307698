/* eslint-disable no-unused-vars */
import React, {
  useEffect, useMemo, useState, useRef,
} from 'react';
import {
  Grid, Box, Typography, Divider,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { BarChart, PieChart, pieArcLabelClasses } from '@mui/x-charts';
import { useMeasure } from 'react-use';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import useReportStyles from './common/useReportStyles';
import TotalCard from './components/TotalCard';
import NavigationBreadCrumbs from './components/NavigationBreadCrumbs';
import palette from '../common/theme/palette';
import CorporateReportFilter from './components/CorporateReportFilter';

const CorporateStatusPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const groups = useSelector((state) => state.groups.items);
  const categories = useSelector((state) => state.categories.items);

  const branchIds = useSelector((state) => state.reports.branchIds);
  const branches = Object.values(groups).filter((group) => branchIds.includes(group.id));

  const devicesWithBranch = useMemo(() => Object.values(devices).map((device) => ({
    ...device,
    branch: groups[groups[groups[device.groupId].groupId].groupId],
  })), [devices, groups]);

  const deviceList = useMemo(() => Object.values(devicesWithBranch).filter((device) => branchIds.includes(device.branch.id)), [devicesWithBranch]);

  const splitByPropertyToObj = (array, property) => array.reduce((acc, item) => {
    const key = item[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  const splitByProperty = (array, property) => Object.values(splitByPropertyToObj(array, property));

  const splittedDevices = splitByPropertyToObj(deviceList, 'category');

  const PieChartData = {
    series:
      [
        {
          type: 'pie',
          yAxisId: 'category',
          label: t('sharedCategory'),
          data: Object.keys(splittedDevices).map((category) => (
            {
              id: category,
              value: splittedDevices[category].length,
              label: `${t(`category${category.replace(/^\w/, (c) => c.toUpperCase())}`)} (${splittedDevices[category].length})`,
            })),
          arcLabel: (item) => String(item.value),
          arcLabelMinAngle: 10,
          innerRadius: 0,
        },
      ],
    colors: palette.branchColors,
    sx: {
      [`& .${pieArcLabelClasses.root}`]: {
        fontWeight: 'bold',
      },
    },
  };

  const BarChartData = {
    dataset: Object.keys(categories).map((key) => {
      const category = categories[key];
      let categoryData = {
        category: category.name,
      };
      branches.forEach((branch) => {
        const branchName = branch.name;
        const branchDevices = deviceList.filter((device) => device.branch.id === branch.id && device.category === category.name);
        const operativeDevices = branchDevices.filter((device) => device.attributes.deviceType === 'heavy-duty' && !device.attributes.maintenance);
        const inoperativeDevices = branchDevices.filter((device) => device.attributes.deviceType === 'heavy-duty' && device.attributes.maintenance);
        categoryData = {
          ...categoryData,
          [`operative${branchName}`]: operativeDevices.length,
          [`inoperative${branchName}`]: inoperativeDevices.length,
        };
      });
      return categoryData;
    }),
    series: branches.map((branch) => ({
      dataKey: `operative${branch.name}`,
      stack: branch.name,
      label: `${branch.name} ${t('maintenanceStatusOperative')}`,
    })).concat(branches.map((branch) => ({
      dataKey: `inoperative${branch.name}`,
      stack: branch.name,
      label: `${branch.name} ${t('maintenanceStatusMaintenance')}`,
    })))
      .sort((a, b) => a.label.localeCompare(b.label)),

    xAxis: [
      {
        id: 'device',
        dataKey: 'category',
        scaleType: 'band',
        tickLabelInterval: () => true,
        valueFormatter: (v) => t(`category${v.replace(/^\w/, (c) => c.toUpperCase())}`).replaceAll(' ', '\n'),
      },
    ],
    colors: palette.branchColors,
  };

  const [PieBoxRef, PieBox] = useMeasure();
  const [legendSize, setLegendSize] = useState({ width: 0, height: 0 });
  const PieTopMargin = 1.5 * PieBox.height > PieBox.width ? (legendSize.height + 10) : 0;
  const PieLeftMargin = 1.5 * PieBox.height > PieBox.width ? 0 : (legendSize.width + 10);
  const LabelRef = useRef(null);

  const [BarBoxRef, BarBox] = useMeasure();
  const [legendSizeBar, setLegendSizeBar] = useState({ top: 0, bottom: 0, left: 0 });
  const BarTopMargin = legendSizeBar.top + 20;
  const BarBottomMargin = legendSizeBar.bottom + 10;
  const BarLeftMargin = legendSizeBar.left;
  const LabelRefBar = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const nodeElements = LabelRef.current ? [...LabelRef.current.children] : [];
      const size = nodeElements.at(-1)?.getBoundingClientRect();
      if (size.height !== 0 && size.width !== 0) {
        setLegendSize(size);
        clearInterval(interval);
      }
    }, 50);
  }, [LabelRef.current?.children, PieBox]);

  useEffect(() => {
    const interval = setInterval(() => {
      const nodeElements = LabelRefBar.current ? [...LabelRefBar.current.children] : [];
      const size = {
        top: nodeElements.at(-2)?.getBoundingClientRect()?.height || 0,
        bottom: nodeElements.at(-4)?.getBoundingClientRect()?.height || 0,
        left: nodeElements.at(-3)?.getBoundingClientRect()?.width || 0,
      };
      if (size.top !== 0 && size.bottom !== 0 && size.left !== 0) {
        setLegendSizeBar(size);
        clearInterval(interval);
      }
    }, 50);
  }, [LabelRefBar.current?.children, BarBox]);

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle']}>
      <div className={classes.container}>
        <div className={classes.header}>
          <CorporateReportFilter
            ignoreDevice
            ignoreProject
            ignoreConstruction
            ignoreCategory
            ignorePeriod
            ignoreSubmit
            includeGroups={['branch', 'project', 'construction']}
            deviceType="heavy-duty"
            isDaily
            showOnly
          >
            {/* <ColumnSelect columns={columns} setColumns={setColumns} columnsArray={columnsArray} /> */}
          </CorporateReportFilter>
        </div>
        <Divider />
        <div className={classes.containerMain}>
          <Grid container sx={{ flex: 1, px: 2 }} spacing={2}>
            {/* LEFT COLUMN */}
            <Grid item container xs={12} md={5} direction="column" spacing={2} sx={{ height: 640 }}>
              {/* PIE CHART */}
              <Grid item sx={{ width: '100%' }} xs>
                <Box sx={{ height: 492, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h4" sx={{ mb: 1 }}>{t('reportDistribution')}</Typography>
                  <Box ref={PieBoxRef} sx={{ flex: 1 }}>
                    <PieChart
                      ref={LabelRef}
                      margin={{ top: PieTopMargin, bottom: 0, left: PieLeftMargin, right: 0 }}
                      slotProps={{
                        legend: {
                          direction: PieTopMargin > 0 ? 'row' : 'column',
                          position: {
                            vertical: PieTopMargin > 0 ? 'top' : 'top',
                            horizontal: PieTopMargin > 0 ? 'middle' : 'left',
                          },
                          padding: 0,
                        },
                      }}
                      {...PieChartData}
                      height={PieBox.height}
                      width={PieBox.width}
                    />
                  </Box>
                </Box>
              </Grid>
              {/* TOTALES */}
              <Grid item container columnSpacing={2} direction="row" xs="auto">
                <TotalCard
                  title={`${t('reportTotal')} ${t('deviceTitle')}`}
                  value={`${deviceList.filter((item) => item.attributes.deviceType === 'heavy-duty').length}`}
                  width={4}
                />
                <TotalCard
                  title={`${t('reportOperative')}`}
                  value={`${deviceList.filter((item) => (item.attributes.deviceType === 'heavy-duty' && !item.attributes.maintenance)).length}`}
                  width={4}
                />
                <TotalCard
                  title={`${t('reportMaintenance')}`}
                  value={`${deviceList.filter((item) => (item.attributes.deviceType === 'heavy-duty' && item.attributes.maintenance)).length}`}
                  width={4}
                />
              </Grid>
            </Grid>
            {/* RIGHT COLUMN */}
            <Grid item container xs={12} md={7} direction="column" spacing={2} sx={{ height: 640 }}>
              <Grid item container xs direction="column">
                <Grid item>
                  <Typography variant="h5">{t('reportDevicesStatus')}</Typography>
                </Grid>
                <Grid ref={BarBoxRef} item xs height={260}>
                  <BarChart
                    ref={LabelRefBar}
                    {...BarChartData}
                    margin={{ top: BarTopMargin, bottom: BarBottomMargin, right: 0, left: BarLeftMargin }}
                    slotProps={{
                      legend: {
                        direction: 'row',
                        position: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        padding: 0,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* TOP 5 */}

          </Grid>
        </div>
      </div>
    </PageLayout>
  );
};

export default CorporateStatusPage;
